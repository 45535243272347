// Headers for marking quote
export const DIRECT_HEADER = 'Please enter the following information to send this quoted price directly back to this shipper\'s system';
export const DIRECT_EMAIL_RESPONDED_HEADER = 'Are you sure you want to send an emailed quote response?';
export const DIRECT_EMAIL_REJECTED_HEADER = 'Are you sure you want to send an emailed quote rejection?';
export const MARK_QUOTE_REJECTED_HEADER = 'Are you sure you want to mark quote as Rejected?';
export const MARK_QUOTE_RESPONDED_HEADER = 'Are you sure you want to mark quote as Responded?';
export const MARK_QUOTE_WON_HEADER = 'Are you sure you want to mark quote as Won?';
export const MARK_QUOTE_LOST_HEADER = 'Are you sure you want to mark quote as Lost?';

// Validation messages
export const VALIDATION_REQUIRED_MSG = 'This field is required';
export const VALIDATION_EMAIL_MSG = 'Please enter a valid email address';

// Upload file messages
export const UPLOAD_CSV_FILE_TITLE = 'Choose a csv file to upload.';
export const UPLOAD_CSV_XLSX_FILE_TITLE = 'Choose a csv or xlsx file to upload.';
export const UPLOAD_XLSX_FILE_TITLE = 'Choose a xlsx file to upload.';
export const SUCCESSFULLY_UPLOADED_FILE_MESSAGE = 'File uploaded and in queue for processing.';
export const FAILED_TO_UPLOAD_FILE_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const DEFAULT_PAGE_MARGIN = '0.5rem';

export const DEFAULT_ROWS_PER_PAGE = 5;

export const DEFAULT_RULE_ORDER = 'a';

export const MAX_FUEL_PRICE_DELAY = 30;

export const MAX_PRICE_VALUE = 999999;

export const NO_NOTES_TEXT = 'No Notes.';

export const REEFER = 'Reefer';

export const RULE_ENGINE_STATUS_NO_REPLY_MESSAGE = 'No actions were taken by the system. Please submit offers manually.';

export const DEFAULT_DISPLAY_VALUE = 'N/A';
